import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendExport } from "@hooks/crud/downloadFiles/useSocialSpendExport";
import { useSocialSpendForTargets } from "@hooks/crud/socialSpend/useSocialSpendForTargets";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { ToolsSocialSpendTable } from "@stories/organisms/PackageTools/ToolsSocialSpendTable";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";

interface PackageDetailsSpendTargetProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  targetId?: string;
  handleOnBack: () => void;
}

export const PackageDetailsSpendTarget = ({
  contractorPackage,
  handleOnBack,
  isLoading,
  targetId,
}: PackageDetailsSpendTargetProps) => {
  const { downloadProgress, downloadStep, getSocialSpendExcel } =
    useSocialSpendExport();

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...newInfo,
    });
  };

  const { isProjectLoading, selectedProject } = useProjectContext();
  const {
    isLoadingMore,
    isSocialSpendLoading,
    socialSpendCount,
    socialSpends,
  } = useSocialSpendForTargets(
    selectedProject?.id,
    targetId,
    contractorPackage?.id,
    sortInfo,
  );

  const loading = (isLoading && isProjectLoading) || isSocialSpendLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );

  return (
    <>
      <Grid container>
        <Grid item md={6} sx={{ paddingLeft: "0px !important" }}>
          {loading ? (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="primary" size="large">
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
              </Skeleton>
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {`Spend - ${
                      selectedTarget
                        ? selectedTarget.targetDescription ||
                          socialSpendCommitmentTypeToString(
                            selectedTarget.targetName,
                          )
                        : ""
                    }`}
                  </Typography>
                </Skeleton>
              </Stack>
            </Grid>
          ) : (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
                <IconButton
                  data-test-id="back-button"
                  size="large"
                  onClick={handleOnBack}
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>

              <Stack>
                <Typography variant="h2">
                  {`Spend - ${
                    selectedTarget
                      ? selectedTarget.targetDescription ||
                        socialSpendCommitmentTypeToString(
                          selectedTarget.targetName,
                        )
                      : ""
                  }`}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            className="blackBtn grey-outline-btn"
            loading={loading}
            disabled={loading || !socialSpends || !socialSpends.length}
            variant="contained"
            onClick={() => {
              if (contractorPackage && targetId) {
                getSocialSpendExcel(
                  contractorPackage.projectId,
                  targetId,
                  contractorPackage?.id,
                );
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Records
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-tb"
          sx={{ margin: "20px 0 0", padding: "0 !important", width: "100%" }}
        >
          <ToolsSocialSpendTable
            loading={loading}
            socialSpends={socialSpends}
            socialSpendCount={socialSpendCount}
            sortInfo={sortInfo}
            isLoadingMore={isLoadingMore}
            handleRequestSort={handleRequestSort}
          />
        </Grid>
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Labour Hour Export"}
      />
    </>
  );
};
