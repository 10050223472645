import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourExport } from "@hooks/crud/downloadFiles/useLabourHourExport";
import { useLabourHoursForTarget } from "@hooks/crud/labourHours/useLabourHoursForTarget";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { LabourHoursTable } from "@stories/organisms/PackageTools/ToolsLabourHoursTable";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";

interface PackageDetailsHoursTargetProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  targetId?: string;
  handlOnBack: () => void;
}

export const PackageDetailsHoursTarget = ({
  contractorPackage,
  handlOnBack,
  isLoading,
  targetId,
}: PackageDetailsHoursTargetProps) => {
  const { downloadProgress, downloadStep, getLabourHourExcel } =
    useLabourHourExport();
  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...newInfo,
    });
  };

  const { isProjectLoading, selectedProject } = useProjectContext();
  const {
    hasMore,
    isLabourHourLoading,
    isLoadingMore,
    labourHourCount,
    labourHours,
  } = useLabourHoursForTarget(
    selectedProject?.id,
    targetId,
    contractorPackage?.id,
    sortInfo,
  );

  const loading = isLoading || isProjectLoading || isLabourHourLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  return (
    <>
      <Grid
        container
        xs={12}
        md={12}
        className="analytic-table"
        sx={{
          display: "flex",
          margin: "0 0 20px",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <Grid item md={6}>
          {loading ? (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="primary" size="large">
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
              </Skeleton>
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {`Labour Hours - ${
                      selectedTarget
                        ? selectedTarget.targetDescription ||
                          labourHourCommitmentTypeToString(
                            selectedTarget.targetName,
                          )
                        : ""
                    }`}
                  </Typography>
                </Skeleton>
              </Stack>
            </Grid>
          ) : (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
                <IconButton
                  data-test-id="back-button"
                  size="large"
                  onClick={handlOnBack}
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>

              <Stack>
                <Typography variant="h2">
                  {`Labour Hours - ${
                    selectedTarget
                      ? selectedTarget.targetDescription ||
                        labourHourCommitmentTypeToString(
                          selectedTarget.targetName,
                        )
                      : ""
                  }`}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            data-test-id="export-targets-button"
            className="blackBtn grey-outline-btn"
            loading={loading}
            disabled={loading || !labourHours || !labourHours.length}
            variant="contained"
            onClick={() => {
              if (contractorPackage?.projectId && targetId) {
                getLabourHourExcel(
                  contractorPackage?.projectId,
                  targetId,
                  contractorPackage?.id,
                );
              }
            }}
            startIcon={<DownloadIcon />}
          >
            Export Records
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table"
          sx={{ marginTop: "20px", padding: "0 !important" }}
        >
          <LabourHoursTable
            loading={loading}
            isLoadingMore={isLoadingMore}
            labourHours={labourHours}
            labourHourCount={labourHourCount}
            sortInfo={sortInfo}
            handleRequestSort={handleRequestSort}
            hasMore={hasMore}
          />
        </Grid>
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Labour Hour Export"}
      />
    </>
  );
};
