import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendExport } from "@hooks/crud/downloadFiles/useSocialSpendExport";
import { useSocialSpendForTargets } from "@hooks/crud/socialSpend/useSocialSpendForTargets";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { ToolsSocialSpendTable } from "@stories/organisms/PackageTools/ToolsSocialSpendTable";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";

export const AnalyticsSpendTarget = () => {
  const navigate = useNavigate();

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    const isAsc =
      sortInfo.orderBy === newInfo.orderBy && sortInfo.order === "asc";
    setSortInfo({
      ...sortInfo,
      ...newInfo,
      order: isAsc ? "desc" : "asc",
    });
  };

  const { contractorPackageId, projectId, reportId, targetId } = useParams();
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getSocialSpendExcel } =
    useSocialSpendExport();

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const {
    isLoadingMore,
    isSocialSpendLoading,
    socialSpendCount,
    socialSpends,
  } = useSocialSpendForTargets(
    projectId,
    targetId,
    contractorPackage?.id,
    sortInfo,
  );

  const handlOnBack = () => {
    if (projectId && contractorPackageId && reportId) {
      navigate(
        RouteHelper.analyticsSpend(projectId, contractorPackageId, reportId),
      );
    }
  };
  const loading = isProjectLoading || isSocialSpendLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );

  const filteredSpend = searchValue
    ? socialSpends.filter((p) =>
        p.organisationName.toLowerCase().includes(inputValue.toLowerCase()),
      )
    : socialSpends;

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Spend - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                socialSpendCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
          handlOnBack={handlOnBack}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
              <StyledButton
                data-test-id="export-targets-button"
                className="blackBtn grey-outline-btn topBarBtn"
                loading={loading}
                disabled={loading || !socialSpends || !socialSpends.length}
                variant="contained"
                onClick={() => {
                  //TODO: Fix this to remove redundant data
                  if (projectId && reportId && targetId) {
                    getSocialSpendExcel(
                      projectId,
                      targetId,
                      isPrimaryPackage ? undefined : contractorPackage?.id,
                    );
                  }
                }}
                startIcon={<DownloadIcon />}
              >
                Export Records
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "0px 40px !important" }}
        >
          <ToolsSocialSpendTable
            loading={loading}
            socialSpends={filteredSpend}
            socialSpendCount={socialSpendCount}
            isLoadingMore={isLoadingMore}
            sortInfo={sortInfo}
            handleRequestSort={handleRequestSort}
          />
        </Grid>
      </Grid>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Social Spend Export"}
      />
    </>
  );
};
